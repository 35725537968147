$(document).ready(function() {
    $(function() {
        var options_start = {
            url: "/airports.json",
            getValue: function(element){
                return element.city_name + " (" + element.icao + ")";
            },
            list: {
                maxNumberOfElements: 10,
                onChooseEvent: function() {
                    var selectedItemValue = $(".airport-select-start").getSelectedItemData().id;
                    $(".airport-id-start").val(selectedItemValue);
                },
                showAnimation: {
                    type: "fade", //normal|slide|fade
                    time: 400,
                    callback: function() {}
                },
                hideAnimation: {
                    type: "fade", //normal|slide|fade
                    time: 400,
                    callback: function() {}
                },
                match: {
                    enabled: true
                }
            },
            theme: "square",
            requestDelay: 500
        };

        var options_landing = {
            url: "/airports.json",
            getValue: function(element){
                return element.city_name + " (" + element.icao + ")";
            },
            list: {
                maxNumberOfElements: 10,
                onChooseEvent: function() {
                    var selectedItemValue = $(".airport-select-landing").getSelectedItemData().id;
                    $(".airport-id-landing").val(selectedItemValue);
                },
                showAnimation: {
                    type: "fade", //normal|slide|fade
                    time: 400,
                    callback: function() {}
                },
                hideAnimation: {
                    type: "fade", //normal|slide|fade
                    time: 400,
                    callback: function() {}
                },
                match: {
                    enabled: true
                }
            },
            theme: "square",
            requestDelay: 500
        };

        $(".airport-select-start").easyAutocomplete(options_start);
        $(".airport-select-landing").easyAutocomplete(options_landing);
    });
});