// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap")
require("toastr")
require("trix")
require("chart.js")
require("chartkick")
require("easy-autocomplete")
require("./sb-admin-2.min")
require("./airport")
require("./airplanes")
require("./bootstrap-datepicker")
require("./locales/bootstrap-datepicker.de.min")


import toastr from 'toastr';

toastr.options = {
    "positionClass": 'toast-top-right',
    "newestOnTop": true,
    "progressBar": true
};

global.toastr = toastr;

document.addEventListener("trix-file-accept", function(event) {
    toastr["error"]("Upload derzeit deaktiviert.", "Hinweis");
    event.preventDefault();
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
