$(document).ready(function() {
    $(function() {
        var option = {
            url: "/airplanes.json",
            getValue: function(element){
                return element.airplane_type + " (" + element.registration + ")";
            },
            list: {
                onSelectItemEvent: function() {
                    var selectedItemValue = $(".airplane-select").getSelectedItemData().id;
                    $(".airplane-id").val(selectedItemValue);
                },
                showAnimation: {
                    type: "fade", //normal|slide|fade
                    time: 400,
                    callback: function() {}
                },
                hideAnimation: {
                    type: "fade", //normal|slide|fade
                    time: 400,
                    callback: function() {}
                },
                match: {
                    enabled: true
                }
            },
            theme: "square"
        };

        $(".airplane-select").easyAutocomplete(option);
    });
});